<div class="form-stepper">
  <div class="">
    <ng-content select="[slot=form-stepper-top]"></ng-content>

    <nav class="mb-4">
      <ul class="stepper-list">
        <li class="stepper-list__item" *ngFor="let f of fieldsets; let i = index">
          <button
            class="stepper-button"
            [class.is--untouched]="!state.touched && !state.active"
            [class.is--active]="state.active"
            [class.is--valid]="state.valid && !state.active"
            [class.is--invalid]="!state.valid && state.touched && !state.active"
            [class.is--finished]="activeStep > i"
            (click)="validateStep(); goToSection(f.name)"
            [disabled]="!activeStepState.valid"
            *ngIf="fieldsetStates?.[f.name] as state"
          >
            <div class="stepper-button__figure me-2">
              <span class="stepper-button__figure__line">
                <span class="sr-only" *ngIf="!state.touched && !state.active && !state.valid">stap nr.{{ i + 1 }}</span>
              </span>
            </div>
            <!--<div class="stepper-button__content">
              <strong class="d-inline-block w-100">{{ f.title }}</strong>
              <ng-container *ngIf="!state.active">
                <span class="text-danger type-body-xs" *ngIf="state?.statusText as text">{{ text }}</span>
              </ng-container>
            </div>-->
          </button>
        </li>
      </ul>
    </nav>

    <ng-content select="[slot=form-stepper-bottom]"></ng-content>
  </div>

  <div class="">
    <form
      [formGroup]="form"
      (ngSubmit)="onSubmit(); validateStep()"
      class="form row"
      [class.hide--validation]="!showValidation"
      [ngClass]="'form--' + appearance"
      #formEl
    >
      <fieldset class="fieldset col-12" *ngFor="let f of fieldsets; let fieldIndex = index">
        <div *ngIf="fieldsetStates?.[f.name] as state" [class.d-none]="!state.active">
          <div class="fieldset__header">
            <legend class="h5 fw-bold text-primary mb-3">{{ f.title || f.name }}</legend>
            <div class="mb-4" *ngIf="f.description" [innerHTML]="f.description"></div>
          </div>
          <div class="row fieldset__main">
            <div class="fieldset__main__inner row" *ngIf="(config | forFieldset : f.name).length">
              <div
                [ngClass]="field.cssClass ?? 'col-12'"
                *ngFor="let field of config | forFieldset : f.name"
                [class.mb-2]="field.fieldType !== 'hidden'"
              >
                <div fstrDynamicField [config]="field" [group]="form" *ngIf="field.control !== 'none' && shouldBeDisplayed(field)"></div>
              </div>
            </div>
          </div>

          <footer class="mt-4 fieldset__footer">
            <!--<pre>{{ fieldsetStates | json }}</pre>-->
            <!--<pre>{{ activeStepState.valid }}</pre>-->
            <button
              class="button button-outline button--muted"
              (click)="prevStep()"
              [disabled]="activeStep === 0"
              [style.opacity]="activeStep === 0 ? 0 : 1"
              type="button"
            >
              <fstr-icon anchor="icon-chevron-left"></fstr-icon>
              <span>Vorige</span>
            </button>
            <button
              class="button button--primary"
              (click)="nextStep()"
              [disabled]="!activeStepState.valid"
              *ngIf="fieldIndex + 1 !== fieldsets.length && showSubmit"
              type="button"
            >
              <span>Volgende</span>
              <fstr-icon anchor="icon-chevron-right"></fstr-icon>
            </button>

            <button
              class="button button--primary"
              type="submit"
              *ngIf="fieldIndex + 1 === fieldsets.length && showSubmit && !hasEdit"
              [disabled]="submitDisabled"
            >
              <span>{{ submitButtonLabel }}</span>
            </button>

            <button
              *ngIf="fieldIndex + 1 > 1 && fieldIndex + 1 >= fieldsets.length && showSubmit && hasEdit"
              class="button button--primary ms-auto me-0"
              type="submit"
              [disabled]="submitDisabled"
            >
              <span>{{ submitButtonLabel }}</span>
            </button>
          </footer>
          <div
            class="d-flex mt-4 p-3 bg-gray-100 align-items-center form-stepper__hassubmit"
            *ngIf="fieldIndex + 1 > 1 && fieldIndex + 1 < fieldsets.length && showSubmit && hasEdit"
          >
            <span class="type-body-xs">{{ 'form-stepper-opslaan' | fromDictionary }}</span>
            <button class="button button--tertiary ms-auto me-0" type="submit" [disabled]="submitDisabled">
              <span>{{ submitButtonLabel }}</span>
            </button>
          </div>
        </div>
      </fieldset>

      <div class="col-12 mt-3">
        <ng-container *ngIf="showAlerts">
          <div *ngIf="triedSubmit && !form.valid" class="alert alert--danger">
            <span>{{ inputErrorMessage || defaultFormConfig.genericInputErrorMessage }}</span>
          </div>

          <div *ngIf="serverError" class="alert alert--danger">
            <span>{{ serverErrorMessage || defaultFormConfig.genericServerErrorMessage }}</span>
          </div>
        </ng-container>

        <ng-content select="[slot=form-alert]"></ng-content>

        <ng-content select="[slot=form-actions]"></ng-content>
      </div>
    </form>

    <pre *ngIf="debug" class="section-inset-t">
      {{ form.getRawValue() | json }}
    </pre>
  </div>
</div>
