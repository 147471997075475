@if (group.get(config.name); as field) {
  <div
    class="custom-field custom-field--has-label"
    [class.has--label]="!!config.label"
    [formGroup]="group"
    [ngClass]="{
      'custom-field--invalid': showError(field)
    }"
  >
    <label class="label">
      <span [innerHTML]="config.label"></span>
      <span *ngIf="isRequired(field)">*</span>
    </label>
    <div class="custom-field-subLabel" *ngIf="config.subLabel" [innerHTML]="config.subLabel"></div>

    <input type="text" [formControlName]="config.name" hidden />

    <!-- File field  -->
    <input
      type="file"
      (change)="onFilesSelected($event)"
      #fileUpload
      multiple
      hidden
      [accept]="config['accept'] ? config['accept'] : '*'"
    />

    <div
      class="file-upload custom-field__field"
      (drop)="onDrop($event)"
      (dragover)="onDragOver($event)"
      (dragleave)="onDragLeave($event)"
      [class.is--drag-over]="dragOver"
      [class.is--not-empty]="attachments.length > 0"
      #fileUpload
    >
      <div class="text-center file-upload__empty-state">
        <strong class="file-upload__empty-state__label">
          {{ 'bestand-uploaden-drop-tekst' | fromDictionary }}
          <a (click)="fileUpload.click()">kies bestand</a>
        </strong>
        <br *ngIf="config['acceptHint']" />
        <span class="type-body-xs text-muted" *ngIf="config['acceptHint']">{{ config['acceptHint'] }}</span>
      </div>
    </div>
    @if (attachments.length) {
      <h3 class="fs-body-xs font-secondary mt-3 mb-0">{{ 'bestanden-uploaden-lijst-titel' | fromDictionary }}</h3>
      <ul class="upload-list mt-2">
        @for (attachment of attachments; track $index) {
          <li class="upload-list__item">
            <div class="upload-bar">
              <div class="upload-bar__figure">
                <img class="upload-bar__figure__image" [src]="attachment.preview" alt="" />
              </div>

              <strong class="upload-bar__label">{{ attachment.file.name }}</strong>

              <div class="upload-bar__actions">
                <button type="button" class="button button-link button--danger d-none d-md-inline-block" (click)="removeFile($index)">
                  Verwijder
                </button>
                <button
                  type="button"
                  class="button button-icon button--danger d-inline-flex d-md-none button--xs"
                  (click)="removeFile($index)"
                >
                  <fstr-icon class="icon-xxs" [anchor]="'icon-alert-close'"></fstr-icon>
                </button>
              </div>
            </div>
          </li>
        }
      </ul>
    }

    <div class="custom-field__error-wrapper">
      <div class="mat-error" *ngIf="showError(field)">
        {{ error(field) }}
      </div>
    </div>
  </div>
}
