import { ChangeDetectorRef, Component, OnInit, inject } from '@angular/core';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { DynamicFormField } from '@teamfoster/dynamic-forms';

@Component({
  selector: 'form-file-input',
  templateUrl: './form-file-input.component.html',
})
export class FormFileInputComponent {
  private domSanitizer = inject(DomSanitizer);
  private cd = inject(ChangeDetectorRef);

  config!: DynamicFormField;
  group!: UntypedFormGroup;

  attachments: { file: File; preview: SafeUrl }[] = [];

  dragOver: boolean = false;
  isPreview = false;

  showError(field: AbstractControl) {
    return field.invalid && (field.touched || field.dirty);
  }

  isRequired(field: AbstractControl) {
    if (!field.validator) {
      return false;
    }
    const validator = field?.validator({} as AbstractControl);
    return validator && validator['required'];
  }

  onFilesSelected(event: any) {
    if (!event.target.files || event.target.files.length === 0) {
      return;
    }

    const files = event.target.files;
    let fileArray = Array.from(files).map(file => ({
      file: file as File,
      preview: this.domSanitizer.bypassSecurityTrustUrl(URL.createObjectURL(file as File)),
    }));

    this.attachments = [...this.attachments, ...fileArray];
    this.setFileDetails();
  }

  setFileDetails() {
    this.group.get(this.config.name)?.markAsDirty();
    this.group.get(this.config.name)?.setValue(this.attachments.map(a => a.file));
  }

  onDrop(event: DragEvent) {
    event.preventDefault();
    const files = event.dataTransfer?.files ?? [];

    if (files.length > 0) {
      let fileArray = Array.from(files)
        .slice(0, 3)
        .map(file => ({
          file: file as File,
          preview: this.domSanitizer.bypassSecurityTrustUrl(URL.createObjectURL(file as File)),
        }));

      this.attachments = [...this.attachments, ...fileArray];
      this.setFileDetails();
      return;
    }

    console.error('no files found on drop event');
  }
  onDragOver(event: any) {
    event.stopPropagation();
    event.preventDefault();
    this.dragOver = true;
  }
  onDragLeave(event: any) {
    event.stopPropagation();
    event.preventDefault();
    this.dragOver = false;
  }

  removeFile(i: number) {
    this.attachments.splice(i, 1);
    this.setFileDetails();
  }

  error(ctrl: AbstractControl) {
    if (ctrl.hasError('required')) {
      return 'Dit veld is verplicht.';
    }
    return null;
  }
}
