import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CoreModule } from '../core/core.module';

import { HttpClientModule } from '@angular/common/http';
import { FormStepperComponent } from './components/form-stepper/form-stepper.component';
import { QuillModule } from 'ngx-quill';
import { IconModule } from '@teamfoster/sdk/icon';
import { DynamicFormModule } from '@teamfoster/dynamic-forms';
import { DictionaryNgrxModule } from '@teamfoster/sdk/dictionary-ngrx';
import { FormFileInputComponent } from './components/form-file-input/form-file-input.component';
import { MatIconModule } from '@angular/material/icon';

// extends dynamic form fields
export const formControls = {
  //image: FormFileInputComponent,
};

@NgModule({
  declarations: [FormStepperComponent, FormFileInputComponent],
  imports: [
    CommonModule,
    CoreModule,
    ReactiveFormsModule,
    HttpClientModule,
    FormsModule,
    QuillModule,
    IconModule,
    DynamicFormModule,
    DictionaryNgrxModule,
    MatIconModule,
  ],
  exports: [FormStepperComponent, FormFileInputComponent],
})
export class FormModule {}
